import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/new.jpg'
import steampic from '../assets/images/header_capsule.png'
import Graph from '../components/graph'
import MoreA from '../components/morea'
import ImgMediaCard from '../components/card'
import { createMedia } from "@artsy/fresnel"

import containerStyles from "./custom.module.css"


import { ResponsivePie } from '@nivo/pie'


import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})



class Analysis extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }



  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }

  calcArch() {
    var innocent =    parseInt(this.state.q5) +   parseInt(this.state.q13) +parseInt(this.state.q34) +parseInt(this.state.q49) +parseInt(this.state.q63) +parseInt(this.state.q65);
    var caregiver =   parseInt(this.state.q7) +   parseInt(this.state.q10) +parseInt(this.state.q15) +parseInt(this.state.q24) +parseInt(this.state.q55) +parseInt(this.state.q68);
    var destroyer =   parseInt(this.state.q2) +   parseInt(this.state.q4) + parseInt(this.state.q21) +parseInt(this.state.q52) +parseInt(this.state.q61) +parseInt(this.state.q66);
    var ruler =       parseInt(this.state.q26) +  parseInt(this.state.q32) +parseInt(this.state.q35) +parseInt(this.state.q38) +parseInt(this.state.q46) +parseInt(this.state.q67);
    var orphan =      parseInt(this.state.q14) +  parseInt(this.state.q22) +parseInt(this.state.q27) +parseInt(this.state.q30) +parseInt(this.state.q50) +parseInt(this.state.q71);
    var seeker =      parseInt(this.state.q33) +  parseInt(this.state.q47) +parseInt(this.state.q51) +parseInt(this.state.q62) +parseInt(this.state.q70) +parseInt(this.state.q72);
    var creator  =    parseInt(this.state.q8) +   parseInt(this.state.q19) +parseInt(this.state.q31) +parseInt(this.state.q60) +parseInt(this.state.q64) +parseInt(this.state.q69);
    var sage =        parseInt(this.state.q1) +   parseInt(this.state.q18) +parseInt(this.state.q20) +parseInt(this.state.q36) +parseInt(this.state.q41) +parseInt(this.state.q56);
    var warrior =     parseInt(this.state.q6) +   parseInt(this.state.q39) +parseInt(this.state.q40) +parseInt(this.state.q44) +parseInt(this.state.q57) +parseInt(this.state.q59);
    var lover =       parseInt(this.state.q12) +  parseInt(this.state.q16) +parseInt(this.state.q17) +parseInt(this.state.q25) +parseInt(this.state.q29) +parseInt(this.state.q45);
    var magician =    parseInt(this.state.q3) +   parseInt(this.state.q23) +parseInt(this.state.q37) +parseInt(this.state.q42) +parseInt(this.state.q48) +parseInt(this.state.q58);
    var fool =        parseInt(this.state.q9) +   parseInt(this.state.q11) +parseInt(this.state.q28) +parseInt(this.state.q43) +parseInt(this.state.q53) +parseInt(this.state.q54);

    var vals_arr = [innocent, caregiver, destroyer, ruler, orphan, seeker, creator, sage, warrior, lover, magician, fool]
    var names_arr = ["innocent", "caregiver", "destroyer", "king", "orphan", "seeker", "creator", "sage", "warrior", "lover", "magician", "fool"]
    var indexOfMaxValue = vals_arr.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);

    this.setState({
      vals_arr: vals_arr,
      names_arr: names_arr,
      best: names_arr[indexOfMaxValue],
      innocent:innocent,
      caregiver: caregiver,
      destroyer: destroyer,
      ruler: ruler,
      orphan: orphan,
      seeker: seeker,
      creator: creator,
      sage: sage,
      warrior: warrior,
      lover: lover,
      magician: magician,
      fool: fool,
      age: this.state.q73,
      mbti1: this.state.q74,
      mbti2: this.state.q75,
      mbti3: this.state.q76,
      mbti4: this.state.q77,
      gender: this.state.q78,
    });

    this.everthingOK(vals_arr);
  }

  calcA() {
    var caregiver= {'INFP': 2577, 'ISFP': 1221, 'INFJ': 1197, 'INTP': 950, 'ENFP': 639, 'ISTP': 606, 'ISFJ': 515, 'INTJ': 372, 'ESFP': 334, 'ENTP': 256, 'ISTJ': 238, 'ENFJ': 245, 'ESFJ': 140, 'ESTP': 122, 'ENTJ': 114, 'ESTJ': 53};
    var seeker= {'INFP': 2068, 'INTP': 1505, 'INFJ': 953, 'INTJ': 848, 'ISTP': 577, 'ISFP': 551, 'ENFP': 500, 'ENTP': 317, 'ISFJ': 294, 'ISTJ': 296, 'ESFP': 191, 'ENFJ': 185, 'ENTJ': 161, 'ESTP': 93, 'ESFJ': 77, 'ESTJ': 62};
    var destroyer= {'INFP': 1285, 'INTP': 983, 'ISFP': 573, 'INFJ': 546, 'INTJ': 561, 'ISTP': 458, 'ISFJ': 278, 'ISTJ': 242, 'ENFP': 207, 'ENTP': 124, 'ENFJ': 81, 'ESFP': 78, 'ENTJ': 62, 'ESFJ': 49, 'ESTP': 36, 'ESTJ': 36};
    var sage= {'INTP': 1106, 'INFP': 556, 'INTJ': 515, 'ISTP': 387, 'INFJ': 391, 'ISFP': 179, 'ISTJ': 156, 'ENTP': 159, 'ISFJ': 101, 'ENFP': 88, 'ESTP': 65, 'ENTJ': 59, 'ENFJ': 42, 'ESFP': 43, 'ESTJ': 22, 'ESFJ': 11};
    var magician= {'INFP': 913, 'INTP': 525, 'INFJ': 409, 'ENFP': 380, 'INTJ': 198, 'ENTP': 190, 'ISFP': 159, 'ISTP': 126, 'ENFJ': 126, 'ESFP': 90, 'ISTJ': 74, 'ENTJ': 66, 'ISFJ': 67, 'ESTP': 60, 'ESFJ': 30, 'ESTJ': 27};
    var creator= {'INFP': 892, 'INTP': 724, 'INTJ': 452, 'INFJ': 452, 'ENFP': 463, 'ENTP': 324, 'ISFP': 211, 'ISTP': 202, 'ENTJ': 151, 'ENFJ': 139, 'ISTJ': 117, 'ESFP': 108, 'ISFJ': 104, 'ESTP': 78, 'ESFJ': 42, 'ESTJ': 44};
    var fool= {'ENFP': 575, 'INFP': 503, 'INTP': 445, 'ENTP': 373, 'ISFP': 203, 'ESFP': 219, 'ISTP': 154, 'INTJ': 150, 'ENTJ': 167, 'ENFJ': 133, 'INFJ': 133, 'ESTP': 107, 'ESTJ': 65, 'ISTJ': 63, 'ESFJ': 66, 'ISFJ': 46};
    var orphan= {'INFP': 531, 'INTP': 358, 'INFJ': 288, 'INTJ': 220, 'ISFP': 204, 'ISTP': 167, 'ISFJ': 111, 'ISTJ': 96, 'ENFP': 82, 'ENTP': 53, 'ENFJ': 40, 'ESFP': 34, 'ENTJ': 26, 'ESTP': 19, 'ESFJ': 13, 'ESTJ': 7};
    var king= {'INTP': 408, 'INTJ': 354, 'ENTP': 304, 'ENFP': 293, 'INFP': 278, 'INFJ': 280, 'ENTJ': 194, 'ENFJ': 147, 'ISTP': 127, 'ISTJ': 85, 'ESFP': 75, 'ESTJ': 76, 'ISFJ': 64, 'ESTP': 74, 'ISFP': 58, 'ESFJ': 56};
    var lover= {'ENFP': 335, 'INFP': 275, 'ENTP': 119, 'INFJ': 112, 'ESFP': 120, 'ENFJ': 124, 'ISFP': 81, 'INTP': 79, 'ISTP': 70, 'ESFJ': 61, 'ISFJ': 41, 'ESTP': 46, 'ENTJ': 40, 'INTJ': 30, 'ESTJ': 19, 'ISTJ': 15};
    var warrior= {'INTJ': 227, 'INTP': 213, 'INFJ': 149, 'INFP': 144, 'ENTP': 97, 'ISTP': 93, 'ISTJ': 89, 'ENTJ': 73, 'ENFP': 65, 'ENFJ': 58, 'ISFP': 42, 'ESTP': 45, 'ISFJ': 41, 'ESTJ': 34, 'ESFP': 19, 'ESFJ': 22};
    var innocent= {'INFP': 126, 'INTP': 75, 'ISTP': 67, 'ISFP': 60, 'INFJ': 53, 'ENFP': 43, 'ISFJ': 31, 'INTJ': 36, 'ESFP': 26, 'ENFJ': 34, 'ISTJ': 18, 'ENTP': 19, 'ENTJ': 16, 'ESFJ': 9, 'ESTP': 11, 'ESTJ': 5};

        var vals_arr = [warrior, sage, lover, king, seeker, creator, innocent, caregiver, destroyer,  orphan,     magician, fool]
        var names_arr = ["Warrior", "Sage", "Lover", "King", "Creator", "Seeker", "Innocent", "Caregiver", "Rebel",  "Orphan",    "Magician", "Fool"]




        var INFP= {'caregiver': 2577, 'seeker': 2068, 'destroyer': 1285, 'magician': 913, 'creator': 892, 'sage': 556, 'orphan': 531, 'fool': 503, 'lover': 275, 'king': 278, 'warrior': 144, 'innocent': 126};
var INTP= {'seeker': 1505, 'sage': 1106, 'destroyer': 983, 'caregiver': 950, 'creator': 724, 'magician': 525, 'fool': 445, 'king': 408, 'orphan': 358, 'warrior': 213, 'innocent': 75, 'lover': 79};
var ISFP= {'caregiver': 1221, 'destroyer': 573, 'seeker': 551, 'creator': 211, 'orphan': 204, 'fool': 203, 'sage': 179, 'magician': 159, 'lover': 81, 'innocent': 60, 'king': 58, 'warrior': 42};
var INFJ= {'caregiver': 1197, 'seeker': 953, 'destroyer': 546, 'creator': 452, 'magician': 409, 'sage': 391, 'orphan': 288, 'king': 280, 'warrior': 149, 'fool': 133, 'lover': 112, 'innocent': 53};
var INTJ= {'seeker': 848, 'destroyer': 561, 'sage': 515, 'creator': 452, 'caregiver': 372, 'king': 354, 'warrior': 227, 'orphan': 220, 'magician': 198, 'fool': 150, 'innocent': 36, 'lover': 30};
var ENFP= {'caregiver': 639, 'fool': 575, 'seeker': 500, 'creator': 463, 'magician': 380, 'lover': 335, 'king': 293, 'destroyer': 207, 'sage': 88, 'orphan': 82, 'warrior': 65, 'innocent': 43};
var ISTP= {'caregiver': 606, 'seeker': 577, 'destroyer': 458, 'sage': 387, 'creator': 202, 'orphan': 167, 'fool': 154, 'magician': 126, 'king': 127, 'warrior': 93, 'innocent': 67, 'lover': 70};
var ISFJ= {'caregiver': 515, 'seeker': 294, 'destroyer': 278, 'orphan': 111, 'sage': 101, 'creator': 104, 'king': 64, 'magician': 67, 'fool': 46, 'lover': 41, 'warrior': 41, 'innocent': 31};
var ENTP= {'fool': 373, 'seeker': 317, 'king': 304, 'creator': 324, 'caregiver': 256, 'magician': 190, 'sage': 159, 'lover': 119, 'warrior': 97, 'destroyer': 124, 'orphan': 53, 'innocent': 19};
var ESFP= {'caregiver': 334, 'seeker': 191, 'fool': 219, 'creator': 108, 'lover': 120, 'magician': 90, 'king': 75, 'destroyer': 78, 'sage': 43, 'orphan': 34, 'innocent': 26, 'warrior': 19};
var ISTJ= {'seeker': 296, 'destroyer': 242, 'caregiver': 238, 'sage': 156, 'creator': 117, 'orphan': 96, 'king': 85, 'warrior': 89, 'magician': 74, 'fool': 63, 'innocent': 18, 'lover': 15};
var ENFJ= {'caregiver': 245, 'seeker': 185, 'king': 147, 'creator': 139, 'fool': 133, 'magician': 126, 'lover': 124, 'destroyer': 81, 'warrior': 58, 'sage': 42, 'orphan': 40, 'innocent': 34};
var ENTJ= {'king': 194, 'seeker': 161, 'fool': 167, 'creator': 151, 'caregiver': 114, 'warrior': 73, 'destroyer': 62, 'magician': 66, 'sage': 59, 'lover': 40, 'orphan': 26, 'innocent': 16};
var ESFJ= {'caregiver': 140, 'seeker': 77, 'lover': 61, 'fool': 66, 'creator': 42, 'king': 56, 'destroyer': 49, 'magician': 30, 'warrior': 22, 'orphan': 13, 'innocent': 9, 'sage': 11};
var ESTP= {'caregiver': 122, 'fool': 107, 'seeker': 93, 'creator': 78, 'sage': 65, 'king': 74, 'magician': 60, 'warrior': 45, 'lover': 46, 'destroyer': 36, 'orphan': 19, 'innocent': 11};
var ESTJ= {'king': 76, 'fool': 65, 'seeker': 62, 'caregiver': 53, 'warrior': 34, 'creator': 44, 'destroyer': 36, 'sage': 22, 'magician': 27, 'lover': 19, 'orphan': 7, 'innocent': 5};


var mbti_arr=[INTP, ENTP, INFP, ENFP, INTJ, ENTJ, INFJ, ENFJ, ISTP, ESTP, ISTJ, ESTJ, ISFP, ESFP, ISFJ, ESFJ]
var mbti_names = ["INTP", "ENTP", "INFP", "ENFP", "INTJ", "ENTJ", "INFJ", "ENFJ", "ISTP", "ESTP", "ISTJ", "ESTJ", "ISFP", "ESFP", "ISFJ", "ESFJ"]


var female= {'caregiver': 660, 'seeker': 565, 'creator': 454, 'lover': 189, 'destroyer': 337, 'sage': 260, 'magician': 296, 'fool': 225, 'king': 286, 'orphan': 131, 'warrior': 101, 'innocent': 58};
var male= {'seeker': 307, 'sage': 232, 'caregiver': 241, 'destroyer': 137, 'creator': 210, 'magician': 129, 'king': 186, 'fool': 169, 'orphan': 54, 'lover': 63, 'warrior': 65, 'innocent': 39};
var unsure= {'orphan': 31, 'seeker': 48, 'caregiver': 33, 'creator': 34, 'destroyer': 29, 'magician': 17, 'innocent': 6, 'sage': 14, 'fool': 18, 'king': 9, 'lover': 7, 'warrior': 4};



        this.setState({
          vals_arr: vals_arr,
          names_arr: names_arr,
          best: names_arr[2],
          innocent:innocent,
          caregiver: caregiver,
          destroyer: destroyer,
          king: king,
          orphan: orphan,
          seeker: seeker,
          creator: creator,
          sage: sage,
          warrior: warrior,
          lover: lover,
          magician: magician,
          fool: fool,


          mbti_arr: mbti_arr,
          mbti_names: mbti_names,

          female: female,
          male: male,
          unsure: unsure,


        });


  }


  componentWillMount() {
    this.calcA();
  }

  render() {
    let name = "Archetypes/MBTI analysis";




    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="Global analysis of 50k users"/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>

            <div >

<h1>Data analysis of 47810 test results </h1>
I am a Czech <a href="https://twitter.com/janjilecek" target="_blank">game developer</a>, progammer and Jungian psychology enthusiast and I created this <Link to="/">Jungian test</Link> on 25th of July 2020 and published it on 26th of July, to celebrate the birthday of <b>the founder of analytical psychology, Carl Gustav Jung (7/26/1875 - 6/6/1961)</b>.


<br/>
Example of a graph you will see here:

<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[0]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[0]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[0]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[0]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[0]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[0]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[0]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[0]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[0]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[0]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[0]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[0]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[0]}</span>
</div>
</div>









<br/><br/>By today, 4th of September 2020, around 80k people took the test and this website got around 200k views - mostly in a span of a week, mainly because it went viral on Filipino and Korean twitter. (Jung is apparently a common Korean last name).
 They somehow made this archetype test into a K-pop meme (?) and started sharing their results everywhere, which brought more traffic to the site.
I think I haven't seen a single twitter profile that shared the meme and DIDN'T have a K-pop celebrity as a profile picture. I assume those were teenage girls - that might be why there is a serious abundance of Caregiver INFPs. The whole viral situation was really weird, so moving on... <br/>

<br/>
I left the MBTI answers optional, so only a half of the archetype results have a complete MBTI string. I am by no means a data scientist, I graduated in CS, not in data science, so please take this as a fun experiment, not hard data. The MBTI values are ofc self-reported, and there is no telling if everyone understood what Sensing/Intuitive means. But let's presume that people who didn't know just left the option empty, and those people are not included in this dataset (it contains only full MBTI strings).

<br/>
Oh, and if it wasn't obvious already, I am an INTP. Creator/Seeker.
<br/>
<br/>

<h2> The results are divided into 3 parts: </h2>
<ul>

<li>Archetype/MBTIs breakdown (all sorted desc.)</li>
<li>MBTI/archetypes breakdown</li>
<li>Gender/archetypes (only 5644 results, as I implemented the gender question later)</li>

</ul>


<h3><b>[1.] Archetype/MBTIs breakdown</b></h3>
            </div>
            <div className={containerStyles.root}>
              <Graph name={this.state.names_arr[0]} best={this.state.best}  data ={[
          {

          "id": "INTP",
          "label": "INTP",
          "value": this.state.vals_arr[0]['INTP'],
          "color": "rgb(2, 0, 5)"
          },
          {
            "id": "ENTP",
            "label": "ENTP",
            "value": this.state.vals_arr[0]['ENTP'],
          "color": "hsl(336, 70%, 50%)"
          },
          {
            "id": "INFP",
            "label": "INFP",
            "value": this.state.vals_arr[0]['INFP'],
          "color": "hsl(60, 70%, 50%)"
          },
          {
            "id": "ENFP",
            "label": "ENFP",
            "value": this.state.vals_arr[0]['ENFP'],
          "color": "hsl(54, 70%, 50%)"
          },
          {
            "id": "INTJ",
            "label": "INTJ",
            "value": this.state.vals_arr[0]['INTJ'],
          "color": "hsl(14, 70%, 50%)"
          },
          {

            "id": "ENTJ",
            "label": "ENTJ",
            "value": this.state.vals_arr[0]['ENTJ'],
          "color": "hsl(92, 70%, 50%)"
          },
          {
            "id": "INFJ",
            "label": "INFJ",
            "value": this.state.vals_arr[0]['INFJ'],
          "color": "hsl(336, 70%, 50%)"
          },
          {
            "id": "ENFJ",
            "label": "ENFJ",
            "value": this.state.vals_arr[0]['ENFJ'],
          "color": "hsl(60, 70%, 50%)"
          },
          {
            "id": "ISTP",
            "label": "ISTP",
            "value": this.state.vals_arr[0]['ISTP'],
          "color": "hsl(54, 70%, 50%)"
          },
          {
            "id": "ESTP",
            "label": "ESTP",
            "value": this.state.vals_arr[0]['ESTP'],
          "color": "hsl(14, 70%, 50%)"
          },
          {

            "id": "ISTJ",
            "label": "ISTJ",
            "value": this.state.vals_arr[0]['ISTJ'],
          "color": "hsl(92, 70%, 50%)"
          },
          {
            "id": "ESTJ",
            "label": "ESTJ",
            "value": this.state.vals_arr[0]['ESTJ'],
          "color": "hsl(336, 70%, 50%)"
        },
        {
          "id": "ISFP",
          "label": "ISFP",
          "value": this.state.vals_arr[0]['ISFP'],
        "color": "hsl(54, 70%, 50%)"
        },
        {
          "id": "ESFP",
          "label": "ESFP",
          "value": this.state.vals_arr[0]['ESFP'],
        "color": "hsl(14, 70%, 50%)"
        },
        {

          "id": "ISFJ",
          "label": "ISFJ",
          "value": this.state.vals_arr[0]['ISFJ'],
        "color": "hsl(92, 70%, 50%)"
        },
        {
          "id": "ESFJ",
          "label": "ESFJ",
          "value": this.state.vals_arr[0]['ESFJ'],
        "color": "hsl(336, 70%, 50%)"
      },
          ]}/>
          <div className={containerStyles.overlay}>
     <span className={containerStyles.totalLabel}>{this.state.names_arr[0]}</span>
   </div>
          </div>



            <div className={containerStyles.root}>
            <Graph  best={this.state.best}  data ={[
        {

        "id": "INTP",
        "label": "INTP",
        "value": this.state.vals_arr[1]['INTP'],
        "color": "rgb(2, 0, 5)"
        },
        {
          "id": "ENTP",
          "label": "ENTP",
          "value": this.state.vals_arr[1]['ENTP'],
        "color": "hsl(336, 70%, 50%)"
        },
        {
          "id": "INFP",
          "label": "INFP",
          "value": this.state.vals_arr[1]['INFP'],
        "color": "hsl(60, 70%, 50%)"
        },
        {
          "id": "ENFP",
          "label": "ENFP",
          "value": this.state.vals_arr[1]['ENFP'],
        "color": "hsl(54, 70%, 50%)"
        },
        {
          "id": "INTJ",
          "label": "INTJ",
          "value": this.state.vals_arr[1]['INTJ'],
        "color": "hsl(14, 70%, 50%)"
        },
        {

          "id": "ENTJ",
          "label": "ENTJ",
          "value": this.state.vals_arr[1]['ENTJ'],
        "color": "hsl(92, 70%, 50%)"
        },
        {
          "id": "INFJ",
          "label": "INFJ",
          "value": this.state.vals_arr[1]['INFJ'],
        "color": "hsl(336, 70%, 50%)"
        },
        {
          "id": "ENFJ",
          "label": "ENFJ",
          "value": this.state.vals_arr[1]['ENFJ'],
        "color": "hsl(60, 70%, 50%)"
        },
        {
          "id": "ISTP",
          "label": "ISTP",
          "value": this.state.vals_arr[1]['ISTP'],
        "color": "hsl(54, 70%, 50%)"
        },
        {
          "id": "ESTP",
          "label": "ESTP",
          "value": this.state.vals_arr[1]['ESTP'],
        "color": "hsl(14, 70%, 50%)"
        },
        {

          "id": "ISTJ",
          "label": "ISTJ",
          "value": this.state.vals_arr[1]['ISTJ'],
        "color": "hsl(92, 70%, 50%)"
        },
        {
          "id": "ESTJ",
          "label": "ESTJ",
          "value": this.state.vals_arr[1]['ESTJ'],
        "color": "hsl(336, 70%, 50%)"
      },
      {
        "id": "ISFP",
        "label": "ISFP",
        "value": this.state.vals_arr[1]['ISFP'],
      "color": "hsl(54, 70%, 50%)"
      },
      {
        "id": "ESFP",
        "label": "ESFP",
        "value": this.state.vals_arr[1]['ESFP'],
      "color": "hsl(14, 70%, 50%)"
      },
      {

        "id": "ISFJ",
        "label": "ISFJ",
        "value": this.state.vals_arr[1]['ISFJ'],
      "color": "hsl(92, 70%, 50%)"
      },
      {
        "id": "ESFJ",
        "label": "ESFJ",
        "value": this.state.vals_arr[1]['ESFJ'],
      "color": "hsl(336, 70%, 50%)"
    },
        ]}/>
        <div className={containerStyles.overlay}>
        <span className={containerStyles.totalLabel}>{this.state.names_arr[1]}</span>
        </div>
        </div>






                    <div className={containerStyles.root}>
                    <Graph  best={this.state.best}  data ={[
                {

                "id": "INTP",
                "label": "INTP",
                "value": this.state.vals_arr[2]['INTP'],
                "color": "rgb(2, 0, 5)"
                },
                {
                  "id": "ENTP",
                  "label": "ENTP",
                  "value": this.state.vals_arr[2]['ENTP'],
                "color": "hsl(336, 70%, 50%)"
                },
                {
                  "id": "INFP",
                  "label": "INFP",
                  "value": this.state.vals_arr[2]['INFP'],
                "color": "hsl(60, 70%, 50%)"
                },
                {
                  "id": "ENFP",
                  "label": "ENFP",
                  "value": this.state.vals_arr[2]['ENFP'],
                "color": "hsl(54, 70%, 50%)"
                },
                {
                  "id": "INTJ",
                  "label": "INTJ",
                  "value": this.state.vals_arr[2]['INTJ'],
                "color": "hsl(14, 70%, 50%)"
                },
                {

                  "id": "ENTJ",
                  "label": "ENTJ",
                  "value": this.state.vals_arr[2]['ENTJ'],
                "color": "hsl(92, 70%, 50%)"
                },
                {
                  "id": "INFJ",
                  "label": "INFJ",
                  "value": this.state.vals_arr[2]['INFJ'],
                "color": "hsl(336, 70%, 50%)"
                },
                {
                  "id": "ENFJ",
                  "label": "ENFJ",
                  "value": this.state.vals_arr[2]['ENFJ'],
                "color": "hsl(60, 70%, 50%)"
                },
                {
                  "id": "ISTP",
                  "label": "ISTP",
                  "value": this.state.vals_arr[2]['ISTP'],
                "color": "hsl(54, 70%, 50%)"
                },
                {
                  "id": "ESTP",
                  "label": "ESTP",
                  "value": this.state.vals_arr[2]['ESTP'],
                "color": "hsl(14, 70%, 50%)"
                },
                {

                  "id": "ISTJ",
                  "label": "ISTJ",
                  "value": this.state.vals_arr[2]['ISTJ'],
                "color": "hsl(92, 70%, 50%)"
                },
                {
                  "id": "ESTJ",
                  "label": "ESTJ",
                  "value": this.state.vals_arr[2]['ESTJ'],
                "color": "hsl(336, 70%, 50%)"
              },
              {
                "id": "ISFP",
                "label": "ISFP",
                "value": this.state.vals_arr[2]['ISFP'],
              "color": "hsl(54, 70%, 50%)"
              },
              {
                "id": "ESFP",
                "label": "ESFP",
                "value": this.state.vals_arr[2]['ESFP'],
              "color": "hsl(14, 70%, 50%)"
              },
              {

                "id": "ISFJ",
                "label": "ISFJ",
                "value": this.state.vals_arr[2]['ISFJ'],
              "color": "hsl(92, 70%, 50%)"
              },
              {
                "id": "ESFJ",
                "label": "ESFJ",
                "value": this.state.vals_arr[2]['ESFJ'],
              "color": "hsl(336, 70%, 50%)"
            },
                ]}/>
                <div className={containerStyles.overlay}>
                <span className={containerStyles.totalLabel}>{this.state.names_arr[2]}</span>
                </div>
                </div>





                            <div className={containerStyles.root}>
                            <Graph  best={this.state.best}  data ={[
                        {

                        "id": "INTP",
                        "label": "INTP",
                        "value": this.state.vals_arr[3]['INTP'],
                        "color": "rgb(2, 0, 5)"
                        },
                        {
                          "id": "ENTP",
                          "label": "ENTP",
                          "value": this.state.vals_arr[3]['ENTP'],
                        "color": "hsl(336, 70%, 50%)"
                        },
                        {
                          "id": "INFP",
                          "label": "INFP",
                          "value": this.state.vals_arr[3]['INFP'],
                        "color": "hsl(60, 70%, 50%)"
                        },
                        {
                          "id": "ENFP",
                          "label": "ENFP",
                          "value": this.state.vals_arr[3]['ENFP'],
                        "color": "hsl(54, 70%, 50%)"
                        },
                        {
                          "id": "INTJ",
                          "label": "INTJ",
                          "value": this.state.vals_arr[3]['INTJ'],
                        "color": "hsl(14, 70%, 50%)"
                        },
                        {

                          "id": "ENTJ",
                          "label": "ENTJ",
                          "value": this.state.vals_arr[3]['ENTJ'],
                        "color": "hsl(92, 70%, 50%)"
                        },
                        {
                          "id": "INFJ",
                          "label": "INFJ",
                          "value": this.state.vals_arr[3]['INFJ'],
                        "color": "hsl(336, 70%, 50%)"
                        },
                        {
                          "id": "ENFJ",
                          "label": "ENFJ",
                          "value": this.state.vals_arr[3]['ENFJ'],
                        "color": "hsl(60, 70%, 50%)"
                        },
                        {
                          "id": "ISTP",
                          "label": "ISTP",
                          "value": this.state.vals_arr[3]['ISTP'],
                        "color": "hsl(54, 70%, 50%)"
                        },
                        {
                          "id": "ESTP",
                          "label": "ESTP",
                          "value": this.state.vals_arr[3]['ESTP'],
                        "color": "hsl(14, 70%, 50%)"
                        },
                        {

                          "id": "ISTJ",
                          "label": "ISTJ",
                          "value": this.state.vals_arr[3]['ISTJ'],
                        "color": "hsl(92, 70%, 50%)"
                        },
                        {
                          "id": "ESTJ",
                          "label": "ESTJ",
                          "value": this.state.vals_arr[3]['ESTJ'],
                        "color": "hsl(336, 70%, 50%)"
                      },
                      {
                        "id": "ISFP",
                        "label": "ISFP",
                        "value": this.state.vals_arr[3]['ISFP'],
                      "color": "hsl(54, 70%, 50%)"
                      },
                      {
                        "id": "ESFP",
                        "label": "ESFP",
                        "value": this.state.vals_arr[3]['ESFP'],
                      "color": "hsl(14, 70%, 50%)"
                      },
                      {

                        "id": "ISFJ",
                        "label": "ISFJ",
                        "value": this.state.vals_arr[3]['ISFJ'],
                      "color": "hsl(92, 70%, 50%)"
                      },
                      {
                        "id": "ESFJ",
                        "label": "ESFJ",
                        "value": this.state.vals_arr[3]['ESFJ'],
                      "color": "hsl(336, 70%, 50%)"
                    },
                        ]}/>
                        <div className={containerStyles.overlay}>
                        <span className={containerStyles.totalLabel}>{this.state.names_arr[3]}</span>
                        </div>
                        </div>





                                    <div className={containerStyles.root}>
                                    <Graph  best={this.state.best}  data ={[
                                {

                                "id": "INTP",
                                "label": "INTP",
                                "value": this.state.vals_arr[4]['INTP'],
                                "color": "rgb(2, 0, 5)"
                                },
                                {
                                  "id": "ENTP",
                                  "label": "ENTP",
                                  "value": this.state.vals_arr[4]['ENTP'],
                                "color": "hsl(336, 70%, 50%)"
                                },
                                {
                                  "id": "INFP",
                                  "label": "INFP",
                                  "value": this.state.vals_arr[4]['INFP'],
                                "color": "hsl(60, 70%, 50%)"
                                },
                                {
                                  "id": "ENFP",
                                  "label": "ENFP",
                                  "value": this.state.vals_arr[4]['ENFP'],
                                "color": "hsl(54, 70%, 50%)"
                                },
                                {
                                  "id": "INTJ",
                                  "label": "INTJ",
                                  "value": this.state.vals_arr[4]['INTJ'],
                                "color": "hsl(14, 70%, 50%)"
                                },
                                {

                                  "id": "ENTJ",
                                  "label": "ENTJ",
                                  "value": this.state.vals_arr[4]['ENTJ'],
                                "color": "hsl(92, 70%, 50%)"
                                },
                                {
                                  "id": "INFJ",
                                  "label": "INFJ",
                                  "value": this.state.vals_arr[4]['INFJ'],
                                "color": "hsl(336, 70%, 50%)"
                                },
                                {
                                  "id": "ENFJ",
                                  "label": "ENFJ",
                                  "value": this.state.vals_arr[4]['ENFJ'],
                                "color": "hsl(60, 70%, 50%)"
                                },
                                {
                                  "id": "ISTP",
                                  "label": "ISTP",
                                  "value": this.state.vals_arr[4]['ISTP'],
                                "color": "hsl(54, 70%, 50%)"
                                },
                                {
                                  "id": "ESTP",
                                  "label": "ESTP",
                                  "value": this.state.vals_arr[4]['ESTP'],
                                "color": "hsl(14, 70%, 50%)"
                                },
                                {

                                  "id": "ISTJ",
                                  "label": "ISTJ",
                                  "value": this.state.vals_arr[4]['ISTJ'],
                                "color": "hsl(92, 70%, 50%)"
                                },
                                {
                                  "id": "ESTJ",
                                  "label": "ESTJ",
                                  "value": this.state.vals_arr[4]['ESTJ'],
                                "color": "hsl(336, 70%, 50%)"
                              },
                              {
                                "id": "ISFP",
                                "label": "ISFP",
                                "value": this.state.vals_arr[4]['ISFP'],
                              "color": "hsl(54, 70%, 50%)"
                              },
                              {
                                "id": "ESFP",
                                "label": "ESFP",
                                "value": this.state.vals_arr[4]['ESFP'],
                              "color": "hsl(14, 70%, 50%)"
                              },
                              {

                                "id": "ISFJ",
                                "label": "ISFJ",
                                "value": this.state.vals_arr[4]['ISFJ'],
                              "color": "hsl(92, 70%, 50%)"
                              },
                              {
                                "id": "ESFJ",
                                "label": "ESFJ",
                                "value": this.state.vals_arr[4]['ESFJ'],
                              "color": "hsl(336, 70%, 50%)"
                            },
                                ]}/>
                                <div className={containerStyles.overlay}>
                                <span className={containerStyles.totalLabel}>{this.state.names_arr[4]}</span>
                                </div>
                                </div>





                                            <div className={containerStyles.root}>
                                            <Graph  best={this.state.best}  data ={[
                                        {

                                        "id": "INTP",
                                        "label": "INTP",
                                        "value": this.state.vals_arr[5]['INTP'],
                                        "color": "rgb(2, 0, 5)"
                                        },
                                        {
                                          "id": "ENTP",
                                          "label": "ENTP",
                                          "value": this.state.vals_arr[5]['ENTP'],
                                        "color": "hsl(336, 70%, 50%)"
                                        },
                                        {
                                          "id": "INFP",
                                          "label": "INFP",
                                          "value": this.state.vals_arr[5]['INFP'],
                                        "color": "hsl(60, 70%, 50%)"
                                        },
                                        {
                                          "id": "ENFP",
                                          "label": "ENFP",
                                          "value": this.state.vals_arr[5]['ENFP'],
                                        "color": "hsl(54, 70%, 50%)"
                                        },
                                        {
                                          "id": "INTJ",
                                          "label": "INTJ",
                                          "value": this.state.vals_arr[5]['INTJ'],
                                        "color": "hsl(14, 70%, 50%)"
                                        },
                                        {

                                          "id": "ENTJ",
                                          "label": "ENTJ",
                                          "value": this.state.vals_arr[5]['ENTJ'],
                                        "color": "hsl(92, 70%, 50%)"
                                        },
                                        {
                                          "id": "INFJ",
                                          "label": "INFJ",
                                          "value": this.state.vals_arr[5]['INFJ'],
                                        "color": "hsl(336, 70%, 50%)"
                                        },
                                        {
                                          "id": "ENFJ",
                                          "label": "ENFJ",
                                          "value": this.state.vals_arr[5]['ENFJ'],
                                        "color": "hsl(60, 70%, 50%)"
                                        },
                                        {
                                          "id": "ISTP",
                                          "label": "ISTP",
                                          "value": this.state.vals_arr[5]['ISTP'],
                                        "color": "hsl(54, 70%, 50%)"
                                        },
                                        {
                                          "id": "ESTP",
                                          "label": "ESTP",
                                          "value": this.state.vals_arr[5]['ESTP'],
                                        "color": "hsl(14, 70%, 50%)"
                                        },
                                        {

                                          "id": "ISTJ",
                                          "label": "ISTJ",
                                          "value": this.state.vals_arr[5]['ISTJ'],
                                        "color": "hsl(92, 70%, 50%)"
                                        },
                                        {
                                          "id": "ESTJ",
                                          "label": "ESTJ",
                                          "value": this.state.vals_arr[5]['ESTJ'],
                                        "color": "hsl(336, 70%, 50%)"
                                      },
                                      {
                                        "id": "ISFP",
                                        "label": "ISFP",
                                        "value": this.state.vals_arr[5]['ISFP'],
                                      "color": "hsl(54, 70%, 50%)"
                                      },
                                      {
                                        "id": "ESFP",
                                        "label": "ESFP",
                                        "value": this.state.vals_arr[5]['ESFP'],
                                      "color": "hsl(14, 70%, 50%)"
                                      },
                                      {

                                        "id": "ISFJ",
                                        "label": "ISFJ",
                                        "value": this.state.vals_arr[5]['ISFJ'],
                                      "color": "hsl(92, 70%, 50%)"
                                      },
                                      {
                                        "id": "ESFJ",
                                        "label": "ESFJ",
                                        "value": this.state.vals_arr[5]['ESFJ'],
                                      "color": "hsl(336, 70%, 50%)"
                                    },
                                        ]}/>
                                        <div className={containerStyles.overlay}>
                                        <span className={containerStyles.totalLabel}>{this.state.names_arr[5]}</span>
                                        </div>
                                        </div>





                                                    <div className={containerStyles.root}>
                                                    <Graph  best={this.state.best}  data ={[
                                                {

                                                "id": "INTP",
                                                "label": "INTP",
                                                "value": this.state.vals_arr[6]['INTP'],
                                                "color": "rgb(2, 0, 5)"
                                                },
                                                {
                                                  "id": "ENTP",
                                                  "label": "ENTP",
                                                  "value": this.state.vals_arr[6]['ENTP'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                {
                                                  "id": "INFP",
                                                  "label": "INFP",
                                                  "value": this.state.vals_arr[6]['INFP'],
                                                "color": "hsl(60, 70%, 50%)"
                                                },
                                                {
                                                  "id": "ENFP",
                                                  "label": "ENFP",
                                                  "value": this.state.vals_arr[6]['ENFP'],
                                                "color": "hsl(54, 70%, 50%)"
                                                },
                                                {
                                                  "id": "INTJ",
                                                  "label": "INTJ",
                                                  "value": this.state.vals_arr[6]['INTJ'],
                                                "color": "hsl(14, 70%, 50%)"
                                                },
                                                {

                                                  "id": "ENTJ",
                                                  "label": "ENTJ",
                                                  "value": this.state.vals_arr[6]['ENTJ'],
                                                "color": "hsl(92, 70%, 50%)"
                                                },
                                                {
                                                  "id": "INFJ",
                                                  "label": "INFJ",
                                                  "value": this.state.vals_arr[6]['INFJ'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                {
                                                  "id": "ENFJ",
                                                  "label": "ENFJ",
                                                  "value": this.state.vals_arr[6]['ENFJ'],
                                                "color": "hsl(60, 70%, 50%)"
                                                },
                                                {
                                                  "id": "ISTP",
                                                  "label": "ISTP",
                                                  "value": this.state.vals_arr[6]['ISTP'],
                                                "color": "hsl(54, 70%, 50%)"
                                                },
                                                {
                                                  "id": "ESTP",
                                                  "label": "ESTP",
                                                  "value": this.state.vals_arr[6]['ESTP'],
                                                "color": "hsl(14, 70%, 50%)"
                                                },
                                                {

                                                  "id": "ISTJ",
                                                  "label": "ISTJ",
                                                  "value": this.state.vals_arr[6]['ISTJ'],
                                                "color": "hsl(92, 70%, 50%)"
                                                },
                                                {
                                                  "id": "ESTJ",
                                                  "label": "ESTJ",
                                                  "value": this.state.vals_arr[6]['ESTJ'],
                                                "color": "hsl(336, 70%, 50%)"
                                              },
                                              {
                                                "id": "ISFP",
                                                "label": "ISFP",
                                                "value": this.state.vals_arr[6]['ISFP'],
                                              "color": "hsl(54, 70%, 50%)"
                                              },
                                              {
                                                "id": "ESFP",
                                                "label": "ESFP",
                                                "value": this.state.vals_arr[6]['ESFP'],
                                              "color": "hsl(14, 70%, 50%)"
                                              },
                                              {

                                                "id": "ISFJ",
                                                "label": "ISFJ",
                                                "value": this.state.vals_arr[6]['ISFJ'],
                                              "color": "hsl(92, 70%, 50%)"
                                              },
                                              {
                                                "id": "ESFJ",
                                                "label": "ESFJ",
                                                "value": this.state.vals_arr[6]['ESFJ'],
                                              "color": "hsl(336, 70%, 50%)"
                                            },
                                                ]}/>
                                                <div className={containerStyles.overlay}>
                                                <span className={containerStyles.totalLabel}>{this.state.names_arr[6]}</span>
                                                </div>
                                                </div>























                                                <div className={containerStyles.root}>
                                                  <Graph name={this.state.names_arr[7]} best={this.state.best}  data ={[
                                                {

                                                "id": "INTP",
                                                "label": "INTP",
                                                "value": this.state.vals_arr[7]['INTP'],
                                                "color": "rgb(2, 0, 5)"
                                                },
                                                {
                                                "id": "ENTP",
                                                "label": "ENTP",
                                                "value": this.state.vals_arr[7]['ENTP'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                {
                                                "id": "INFP",
                                                "label": "INFP",
                                                "value": this.state.vals_arr[7]['INFP'],
                                                "color": "hsl(60, 70%, 50%)"
                                                },
                                                {
                                                "id": "ENFP",
                                                "label": "ENFP",
                                                "value": this.state.vals_arr[7]['ENFP'],
                                                "color": "hsl(54, 70%, 50%)"
                                                },
                                                {
                                                "id": "INTJ",
                                                "label": "INTJ",
                                                "value": this.state.vals_arr[7]['INTJ'],
                                                "color": "hsl(14, 70%, 50%)"
                                                },
                                                {

                                                "id": "ENTJ",
                                                "label": "ENTJ",
                                                "value": this.state.vals_arr[7]['ENTJ'],
                                                "color": "hsl(92, 70%, 50%)"
                                                },
                                                {
                                                "id": "INFJ",
                                                "label": "INFJ",
                                                "value": this.state.vals_arr[7]['INFJ'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                {
                                                "id": "ENFJ",
                                                "label": "ENFJ",
                                                "value": this.state.vals_arr[7]['ENFJ'],
                                                "color": "hsl(60, 70%, 50%)"
                                                },
                                                {
                                                "id": "ISTP",
                                                "label": "ISTP",
                                                "value": this.state.vals_arr[7]['ISTP'],
                                                "color": "hsl(54, 70%, 50%)"
                                                },
                                                {
                                                "id": "ESTP",
                                                "label": "ESTP",
                                                "value": this.state.vals_arr[7]['ESTP'],
                                                "color": "hsl(14, 70%, 50%)"
                                                },
                                                {

                                                "id": "ISTJ",
                                                "label": "ISTJ",
                                                "value": this.state.vals_arr[7]['ISTJ'],
                                                "color": "hsl(92, 70%, 50%)"
                                                },
                                                {
                                                "id": "ESTJ",
                                                "label": "ESTJ",
                                                "value": this.state.vals_arr[7]['ESTJ'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                {
                                                "id": "ISFP",
                                                "label": "ISFP",
                                                "value": this.state.vals_arr[7]['ISFP'],
                                                "color": "hsl(54, 70%, 50%)"
                                                },
                                                {
                                                "id": "ESFP",
                                                "label": "ESFP",
                                                "value": this.state.vals_arr[7]['ESFP'],
                                                "color": "hsl(14, 70%, 50%)"
                                                },
                                                {

                                                "id": "ISFJ",
                                                "label": "ISFJ",
                                                "value": this.state.vals_arr[7]['ISFJ'],
                                                "color": "hsl(92, 70%, 50%)"
                                                },
                                                {
                                                "id": "ESFJ",
                                                "label": "ESFJ",
                                                "value": this.state.vals_arr[7]['ESFJ'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                ]}/>
                                                <div className={containerStyles.overlay}>
                                                <span className={containerStyles.totalLabel}>{this.state.names_arr[7]}</span>
                                                </div>
                                                </div>



                                                <div className={containerStyles.root}>
                                                <Graph  best={this.state.best}  data ={[
                                                {

                                                "id": "INTP",
                                                "label": "INTP",
                                                "value": this.state.vals_arr[8]['INTP'],
                                                "color": "rgb(2, 0, 5)"
                                                },
                                                {
                                                "id": "ENTP",
                                                "label": "ENTP",
                                                "value": this.state.vals_arr[8]['ENTP'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                {
                                                "id": "INFP",
                                                "label": "INFP",
                                                "value": this.state.vals_arr[8]['INFP'],
                                                "color": "hsl(60, 70%, 50%)"
                                                },
                                                {
                                                "id": "ENFP",
                                                "label": "ENFP",
                                                "value": this.state.vals_arr[8]['ENFP'],
                                                "color": "hsl(54, 70%, 50%)"
                                                },
                                                {
                                                "id": "INTJ",
                                                "label": "INTJ",
                                                "value": this.state.vals_arr[8]['INTJ'],
                                                "color": "hsl(14, 70%, 50%)"
                                                },
                                                {

                                                "id": "ENTJ",
                                                "label": "ENTJ",
                                                "value": this.state.vals_arr[8]['ENTJ'],
                                                "color": "hsl(92, 70%, 50%)"
                                                },
                                                {
                                                "id": "INFJ",
                                                "label": "INFJ",
                                                "value": this.state.vals_arr[8]['INFJ'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                {
                                                "id": "ENFJ",
                                                "label": "ENFJ",
                                                "value": this.state.vals_arr[8]['ENFJ'],
                                                "color": "hsl(60, 70%, 50%)"
                                                },
                                                {
                                                "id": "ISTP",
                                                "label": "ISTP",
                                                "value": this.state.vals_arr[8]['ISTP'],
                                                "color": "hsl(54, 70%, 50%)"
                                                },
                                                {
                                                "id": "ESTP",
                                                "label": "ESTP",
                                                "value": this.state.vals_arr[8]['ESTP'],
                                                "color": "hsl(14, 70%, 50%)"
                                                },
                                                {

                                                "id": "ISTJ",
                                                "label": "ISTJ",
                                                "value": this.state.vals_arr[8]['ISTJ'],
                                                "color": "hsl(92, 70%, 50%)"
                                                },
                                                {
                                                "id": "ESTJ",
                                                "label": "ESTJ",
                                                "value": this.state.vals_arr[8]['ESTJ'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                {
                                                "id": "ISFP",
                                                "label": "ISFP",
                                                "value": this.state.vals_arr[8]['ISFP'],
                                                "color": "hsl(54, 70%, 50%)"
                                                },
                                                {
                                                "id": "ESFP",
                                                "label": "ESFP",
                                                "value": this.state.vals_arr[8]['ESFP'],
                                                "color": "hsl(14, 70%, 50%)"
                                                },
                                                {

                                                "id": "ISFJ",
                                                "label": "ISFJ",
                                                "value": this.state.vals_arr[8]['ISFJ'],
                                                "color": "hsl(92, 70%, 50%)"
                                                },
                                                {
                                                "id": "ESFJ",
                                                "label": "ESFJ",
                                                "value": this.state.vals_arr[8]['ESFJ'],
                                                "color": "hsl(336, 70%, 50%)"
                                                },
                                                ]}/>
                                                <div className={containerStyles.overlay}>
                                                <span className={containerStyles.totalLabel}>{this.state.names_arr[8]}</span>
                                                </div>
                                                </div>






                                                        <div className={containerStyles.root}>
                                                        <Graph  best={this.state.best}  data ={[
                                                    {

                                                    "id": "INTP",
                                                    "label": "INTP",
                                                    "value": this.state.vals_arr[9]['INTP'],
                                                    "color": "rgb(2, 0, 5)"
                                                    },
                                                    {
                                                      "id": "ENTP",
                                                      "label": "ENTP",
                                                      "value": this.state.vals_arr[9]['ENTP'],
                                                    "color": "hsl(336, 70%, 50%)"
                                                    },
                                                    {
                                                      "id": "INFP",
                                                      "label": "INFP",
                                                      "value": this.state.vals_arr[9]['INFP'],
                                                    "color": "hsl(60, 70%, 50%)"
                                                    },
                                                    {
                                                      "id": "ENFP",
                                                      "label": "ENFP",
                                                      "value": this.state.vals_arr[9]['ENFP'],
                                                    "color": "hsl(54, 70%, 50%)"
                                                    },
                                                    {
                                                      "id": "INTJ",
                                                      "label": "INTJ",
                                                      "value": this.state.vals_arr[9]['INTJ'],
                                                    "color": "hsl(14, 70%, 50%)"
                                                    },
                                                    {

                                                      "id": "ENTJ",
                                                      "label": "ENTJ",
                                                      "value": this.state.vals_arr[9]['ENTJ'],
                                                    "color": "hsl(92, 70%, 50%)"
                                                    },
                                                    {
                                                      "id": "INFJ",
                                                      "label": "INFJ",
                                                      "value": this.state.vals_arr[9]['INFJ'],
                                                    "color": "hsl(336, 70%, 50%)"
                                                    },
                                                    {
                                                      "id": "ENFJ",
                                                      "label": "ENFJ",
                                                      "value": this.state.vals_arr[9]['ENFJ'],
                                                    "color": "hsl(60, 70%, 50%)"
                                                    },
                                                    {
                                                      "id": "ISTP",
                                                      "label": "ISTP",
                                                      "value": this.state.vals_arr[9]['ISTP'],
                                                    "color": "hsl(54, 70%, 50%)"
                                                    },
                                                    {
                                                      "id": "ESTP",
                                                      "label": "ESTP",
                                                      "value": this.state.vals_arr[9]['ESTP'],
                                                    "color": "hsl(14, 70%, 50%)"
                                                    },
                                                    {

                                                      "id": "ISTJ",
                                                      "label": "ISTJ",
                                                      "value": this.state.vals_arr[9]['ISTJ'],
                                                    "color": "hsl(92, 70%, 50%)"
                                                    },
                                                    {
                                                      "id": "ESTJ",
                                                      "label": "ESTJ",
                                                      "value": this.state.vals_arr[9]['ESTJ'],
                                                    "color": "hsl(336, 70%, 50%)"
                                                  },
                                                  {
                                                    "id": "ISFP",
                                                    "label": "ISFP",
                                                    "value": this.state.vals_arr[9]['ISFP'],
                                                  "color": "hsl(54, 70%, 50%)"
                                                  },
                                                  {
                                                    "id": "ESFP",
                                                    "label": "ESFP",
                                                    "value": this.state.vals_arr[9]['ESFP'],
                                                  "color": "hsl(14, 70%, 50%)"
                                                  },
                                                  {

                                                    "id": "ISFJ",
                                                    "label": "ISFJ",
                                                    "value": this.state.vals_arr[9]['ISFJ'],
                                                  "color": "hsl(92, 70%, 50%)"
                                                  },
                                                  {
                                                    "id": "ESFJ",
                                                    "label": "ESFJ",
                                                    "value": this.state.vals_arr[9]['ESFJ'],
                                                  "color": "hsl(336, 70%, 50%)"
                                                },
                                                    ]}/>
                                                    <div className={containerStyles.overlay}>
                                                    <span className={containerStyles.totalLabel}>{this.state.names_arr[9]}</span>
                                                    </div>
                                                    </div>





                                                                <div className={containerStyles.root}>
                                                                <Graph  best={this.state.best}  data ={[
                                                            {

                                                            "id": "INTP",
                                                            "label": "INTP",
                                                            "value": this.state.vals_arr[10]['INTP'],
                                                            "color": "rgb(2, 0, 5)"
                                                            },
                                                            {
                                                              "id": "ENTP",
                                                              "label": "ENTP",
                                                              "value": this.state.vals_arr[10]['ENTP'],
                                                            "color": "hsl(336, 70%, 50%)"
                                                            },
                                                            {
                                                              "id": "INFP",
                                                              "label": "INFP",
                                                              "value": this.state.vals_arr[10]['INFP'],
                                                            "color": "hsl(60, 70%, 50%)"
                                                            },
                                                            {
                                                              "id": "ENFP",
                                                              "label": "ENFP",
                                                              "value": this.state.vals_arr[10]['ENFP'],
                                                            "color": "hsl(54, 70%, 50%)"
                                                            },
                                                            {
                                                              "id": "INTJ",
                                                              "label": "INTJ",
                                                              "value": this.state.vals_arr[10]['INTJ'],
                                                            "color": "hsl(14, 70%, 50%)"
                                                            },
                                                            {

                                                              "id": "ENTJ",
                                                              "label": "ENTJ",
                                                              "value": this.state.vals_arr[10]['ENTJ'],
                                                            "color": "hsl(92, 70%, 50%)"
                                                            },
                                                            {
                                                              "id": "INFJ",
                                                              "label": "INFJ",
                                                              "value": this.state.vals_arr[10]['INFJ'],
                                                            "color": "hsl(336, 70%, 50%)"
                                                            },
                                                            {
                                                              "id": "ENFJ",
                                                              "label": "ENFJ",
                                                              "value": this.state.vals_arr[10]['ENFJ'],
                                                            "color": "hsl(60, 70%, 50%)"
                                                            },
                                                            {
                                                              "id": "ISTP",
                                                              "label": "ISTP",
                                                              "value": this.state.vals_arr[10]['ISTP'],
                                                            "color": "hsl(54, 70%, 50%)"
                                                            },
                                                            {
                                                              "id": "ESTP",
                                                              "label": "ESTP",
                                                              "value": this.state.vals_arr[10]['ESTP'],
                                                            "color": "hsl(14, 70%, 50%)"
                                                            },
                                                            {

                                                              "id": "ISTJ",
                                                              "label": "ISTJ",
                                                              "value": this.state.vals_arr[10]['ISTJ'],
                                                            "color": "hsl(92, 70%, 50%)"
                                                            },
                                                            {
                                                              "id": "ESTJ",
                                                              "label": "ESTJ",
                                                              "value": this.state.vals_arr[10]['ESTJ'],
                                                            "color": "hsl(336, 70%, 50%)"
                                                          },
                                                          {
                                                            "id": "ISFP",
                                                            "label": "ISFP",
                                                            "value": this.state.vals_arr[10]['ISFP'],
                                                          "color": "hsl(54, 70%, 50%)"
                                                          },
                                                          {
                                                            "id": "ESFP",
                                                            "label": "ESFP",
                                                            "value": this.state.vals_arr[10]['ESFP'],
                                                          "color": "hsl(14, 70%, 50%)"
                                                          },
                                                          {

                                                            "id": "ISFJ",
                                                            "label": "ISFJ",
                                                            "value": this.state.vals_arr[10]['ISFJ'],
                                                          "color": "hsl(92, 70%, 50%)"
                                                          },
                                                          {
                                                            "id": "ESFJ",
                                                            "label": "ESFJ",
                                                            "value": this.state.vals_arr[10]['ESFJ'],
                                                          "color": "hsl(336, 70%, 50%)"
                                                        },
                                                            ]}/>
                                                            <div className={containerStyles.overlay}>
                                                            <span className={containerStyles.totalLabel}>{this.state.names_arr[10]}</span>
                                                            </div>
                                                            </div>





                                                                        <div className={containerStyles.root}>
                                                                        <Graph  best={this.state.best}  data ={[
                                                                    {

                                                                    "id": "INTP",
                                                                    "label": "INTP",
                                                                    "value": this.state.vals_arr[11]['INTP'],
                                                                    "color": "rgb(2, 0, 5)"
                                                                    },
                                                                    {
                                                                      "id": "ENTP",
                                                                      "label": "ENTP",
                                                                      "value": this.state.vals_arr[11]['ENTP'],
                                                                    "color": "hsl(336, 70%, 50%)"
                                                                    },
                                                                    {
                                                                      "id": "INFP",
                                                                      "label": "INFP",
                                                                      "value": this.state.vals_arr[11]['INFP'],
                                                                    "color": "hsl(60, 70%, 50%)"
                                                                    },
                                                                    {
                                                                      "id": "ENFP",
                                                                      "label": "ENFP",
                                                                      "value": this.state.vals_arr[11]['ENFP'],
                                                                    "color": "hsl(54, 70%, 50%)"
                                                                    },
                                                                    {
                                                                      "id": "INTJ",
                                                                      "label": "INTJ",
                                                                      "value": this.state.vals_arr[11]['INTJ'],
                                                                    "color": "hsl(14, 70%, 50%)"
                                                                    },
                                                                    {

                                                                      "id": "ENTJ",
                                                                      "label": "ENTJ",
                                                                      "value": this.state.vals_arr[11]['ENTJ'],
                                                                    "color": "hsl(92, 70%, 50%)"
                                                                    },
                                                                    {
                                                                      "id": "INFJ",
                                                                      "label": "INFJ",
                                                                      "value": this.state.vals_arr[11]['INFJ'],
                                                                    "color": "hsl(336, 70%, 50%)"
                                                                    },
                                                                    {
                                                                      "id": "ENFJ",
                                                                      "label": "ENFJ",
                                                                      "value": this.state.vals_arr[11]['ENFJ'],
                                                                    "color": "hsl(60, 70%, 50%)"
                                                                    },
                                                                    {
                                                                      "id": "ISTP",
                                                                      "label": "ISTP",
                                                                      "value": this.state.vals_arr[11]['ISTP'],
                                                                    "color": "hsl(54, 70%, 50%)"
                                                                    },
                                                                    {
                                                                      "id": "ESTP",
                                                                      "label": "ESTP",
                                                                      "value": this.state.vals_arr[11]['ESTP'],
                                                                    "color": "hsl(14, 70%, 50%)"
                                                                    },
                                                                    {

                                                                      "id": "ISTJ",
                                                                      "label": "ISTJ",
                                                                      "value": this.state.vals_arr[11]['ISTJ'],
                                                                    "color": "hsl(92, 70%, 50%)"
                                                                    },
                                                                    {
                                                                      "id": "ESTJ",
                                                                      "label": "ESTJ",
                                                                      "value": this.state.vals_arr[11]['ESTJ'],
                                                                    "color": "hsl(336, 70%, 50%)"
                                                                  },
                                                                  {
                                                                    "id": "ISFP",
                                                                    "label": "ISFP",
                                                                    "value": this.state.vals_arr[11]['ISFP'],
                                                                  "color": "hsl(54, 70%, 50%)"
                                                                  },
                                                                  {
                                                                    "id": "ESFP",
                                                                    "label": "ESFP",
                                                                    "value": this.state.vals_arr[11]['ESFP'],
                                                                  "color": "hsl(14, 70%, 50%)"
                                                                  },
                                                                  {

                                                                    "id": "ISFJ",
                                                                    "label": "ISFJ",
                                                                    "value": this.state.vals_arr[11]['ISFJ'],
                                                                  "color": "hsl(92, 70%, 50%)"
                                                                  },
                                                                  {
                                                                    "id": "ESFJ",
                                                                    "label": "ESFJ",
                                                                    "value": this.state.vals_arr[11]['ESFJ'],
                                                                  "color": "hsl(336, 70%, 50%)"
                                                                },
                                                                    ]}/>
                                                                    <div className={containerStyles.overlay}>
                                                                    <span className={containerStyles.totalLabel}>{this.state.names_arr[11]}</span>
                                                                    </div>
                                                                    </div>

<hr/>
<h3><b>If you want to know more about archetypes and Jungian psychology...</b></h3>

<MediaContextProvider>
    <Media at="sm">
    <center>
    <ImgMediaCard/>
    </center>
    </Media>
    <Media at="md">
    <div>
    <div>
      Jung's Labyrinth is a psychological exploration PC game that uses Jungian psychology, mythology, alchemical and dream symbolism in a series of active imaginations to map the process of <b>individuation</b>, becoming who you really are.. Confront your inner archetypes, collect messages that other travelers left behind and reach your Self.
    </div>

      <center>  <a href={"https://store.steampowered.com/app/709710?utm_source=archetypes&utm_campaign=" + this.arch} className="button" onClick="window.fbq('track', 'AddToWishlist')"><div  className={containerStyles.vert}> <div>Get it on Steam</div></div></a></center>

    <div className="video">
    <iframe width="100%" height="400" src="https://www.youtube.com/embed/GjX9cSc2BLw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    </div>
    </Media>
    <Media greaterThanOrEqual="lg">
    <div>
    <div>
      Jung's Labyrinth is a psychological exploration PC game that uses Jungian psychology, mythology, alchemical and dream symbolism in a series of active imaginations to map the process of <b>individuation</b>, becoming who you really are. Confront your inner archetypes, collect messages that other travelers left behind and reach your Self.
    </div>

    <center>  <a href={"https://store.steampowered.com/app/709710?utm_source=archetypes&utm_campaign=" + this.arch} className="button" onClick="window.fbq('track', 'AddToWishlist')"><div  className={containerStyles.vert}> <div>Get it on Steam</div></div></a></center>

    <div className="video">
    <iframe width="100%" height="700" src="https://www.youtube.com/embed/GjX9cSc2BLw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    </div>
    </Media>
  </MediaContextProvider>




<hr/>
<h3><b>[2.] MBTI/archetypes breakdown</b> </h3>


<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[0]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[0]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[0]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[0]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[0]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[0]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[0]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[0]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[0]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[0]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[0]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[0]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[0]}</span>
</div>
</div>


















<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[1]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[1]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[1]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[1]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[1]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[1]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[1]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[1]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[1]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[1]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[1]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[1]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[1]}</span>
</div>
</div>























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[2]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[2]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[2]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[2]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[2]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[2]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[2]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[2]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[2]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[2]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[2]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[2]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[2]}</span>
</div>
</div>

















<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[3]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[3]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[3]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[3]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[3]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[3]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[3]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[3]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[3]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[3]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[3]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[3]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[3]}</span>
</div>
</div>

















<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[4]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[4]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[4]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[4]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[4]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[4]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[4]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[4]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[4]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[4]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[4]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[4]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[4]}</span>
</div>
</div>




















<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[5]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[5]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[5]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[5]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[5]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[5]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[5]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[5]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[5]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[5]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[5]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[5]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[5]}</span>
</div>
</div>






















<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[6]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[6]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[6]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[6]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[6]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[6]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[6]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[6]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[6]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[6]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[6]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[6]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[6]}</span>
</div>
</div>























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[7]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[7]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[7]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[7]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[7]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[7]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[7]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[7]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[7]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[7]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[7]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[7]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[7]}</span>
</div>
</div>























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[8]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[8]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[8]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[8]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[8]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[8]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[8]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[8]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[8]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[8]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[8]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[8]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[8]}</span>
</div>
</div>



























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[9]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[9]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[9]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[9]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[9]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[9]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[9]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[9]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[9]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[9]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[9]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[9]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[9]}</span>
</div>
</div>

























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[10]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[10]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[10]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[10]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[10]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[10]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[10]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[10]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[10]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[10]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[10]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[10]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[10]}</span>
</div>
</div>

























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[11]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[11]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[11]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[11]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[11]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[11]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[11]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[11]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[11]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[11]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[11]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[11]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[11]}</span>
</div>
</div>























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[12]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[12]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[12]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[12]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[12]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[12]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[12]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[12]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[12]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[12]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[12]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[12]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[12]}</span>
</div>
</div>


























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[13]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[13]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[13]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[13]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[13]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[13]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[13]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[13]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[13]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[13]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[13]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[13]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[13]}</span>
</div>
</div>
























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[14]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[14]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[14]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[14]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[14]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[14]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[14]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[14]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[14]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[14]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[14]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[14]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[14]}</span>
</div>
</div>



























<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.mbti_arr[15]['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.mbti_arr[15]['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.mbti_arr[15]['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.mbti_arr[15]['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.mbti_arr[15]['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.mbti_arr[15]['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.mbti_arr[15]['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.mbti_arr[15]['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.mbti_arr[15]['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.mbti_arr[15]['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.mbti_arr[15]['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.mbti_arr[15]['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>{this.state.mbti_names[15]}</span>
</div>
</div>


<hr/>
<h3><b>[3.] Gender/archetypes breakdown</b> </h3>



<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.female['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.female['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.female['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.female['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.female['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.female['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.female['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.female['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.female['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.female['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.female['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.female['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>Female</span>
</div>
</div>





<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.male['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.male['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.male['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.male['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.male['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.male['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.male['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.male['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.male['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.male['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.male['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.male['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>Male</span>
</div>
</div>




<div className={containerStyles.root}>
<Graph  best={this.state.best}  data ={[
{

"id": "seeker",
"label": "seeker",
"value": this.state.unsure['seeker'],
"color": "rgb(2, 0, 5)"
},
{
"id": "innocent",
"label": "innocent",
"value": this.state.unsure['innocent'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "orphan",
"label": "orphan",
"value": this.state.unsure['orphan'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "fool",
"label": "fool",
"value": this.state.unsure['fool'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "sage",
"label": "sage",
"value": this.state.unsure['sage'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "king",
"label": "king",
"value": this.state.unsure['king'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "creator",
"label": "creator",
"value": this.state.unsure['creator'],
"color": "hsl(336, 70%, 50%)"
},
{
"id": "destroyer",
"label": "destroyer",
"value": this.state.unsure['destroyer'],
"color": "hsl(60, 70%, 50%)"
},
{
"id": "magician",
"label": "magician",
"value": this.state.unsure['magician'],
"color": "hsl(54, 70%, 50%)"
},
{
"id": "caregiver",
"label": "caregiver",
"value": this.state.unsure['caregiver'],
"color": "hsl(14, 70%, 50%)"
},
{

"id": "lover",
"label": "lover",
"value": this.state.unsure['lover'],
"color": "hsl(92, 70%, 50%)"
},
{
"id": "warrior",
"label": "warrior",
"value": this.state.unsure['warrior'],
"color": "hsl(336, 70%, 50%)"
},
]}/>
<div className={containerStyles.overlay}>
<span className={containerStyles.totalLabel}>Unsure</span>
</div>
</div>



          </section>



        </div>
      </Layout>
    )
  }
}

export default Analysis
